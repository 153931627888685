<template>
  <div class="addFactureComponent">

    <CForm v-on:submit.prevent="validateFactureCreation()">
      <CSelect
        :value.sync="exerciceComptable"
        label="Exercice associé"
        @input="$v.exerciceComptable.$touch()"
        :isValid="$v.exerciceComptable.$dirty ? !$v.exerciceComptable.$error : null"
        :options='exerciceComptableYears'
        custom
      />

      <CInput
        label="Intitulé de votre facture "
        v-model="title" type="text"
        placeholder="Renseignez l'intitulé de votre facture"
        maxlength="149"
        @input="$v.title.$touch()"
        :isValid="$v.title.$dirty ? !$v.title.$error : null"
      >
      </CInput>

      <CInput
        label="Adresse de facturation "
        v-model="address" type="text"
        placeholder="Renseignez l'adresse de facturation"
        maxlength="300"
        @input="$v.address.$touch()"
        :isValid="$v.address.$dirty ? !$v.address.$error : null"
      >
      </CInput>

      <CRow class="mb-2">
        <CCol>
          <CRow>
            <CCol tag="label">
              Modalités de paiement possibles
              <small class="text-danger" v-if="!isAtLeastOneModalitePaiementChosen">
                Veuillez choisir au moins une modalité de paiement
              </small>
            </CCol>
          </CRow>
          <CRow>
            <CCol class="form-inline">
              <CInputCheckbox
                label="Virement"
                :custom="true"
                name="new-facture-input-isModalitesVirement"
                :checked.sync="isModalitesVirement"
                :inline="true"
              />
              <CInputCheckbox
                label="Carte bancaire"
                :custom="true"
                name="new-facture-input-isModalitesCb"
                :checked.sync="isModalitesCb"
                :inline="true"
              />
              <CInputCheckbox
                label="Liquide"
                :custom="true"
                name="new-facture-input-isModalitesCash"
                :checked.sync="isModalitesCash"
                :inline="true"
              />
              <CInputCheckbox
                label="Chèque"
                :custom="true"
                name="new-facture-input-isModalitesCheque"
                :checked.sync="isModalitesCheque"
                :inline="true"
              />
            </CCol>
          </CRow>
        </CCol>
      </CRow>

      <CSelect
        :value.sync="delaiPaiement"
        label="Délais de paiement"
        :options="delaiPaiementArrayOptions"
        @input="$v.delaiPaiement.$touch()"
        :isValid="$v.delaiPaiement.$dirty ? !$v.delaiPaiement.$error : null"
        custom
      />

      <CRow>
        <CCol sm="6">

          <div v-if="$browserDetect.isSafari">
            <b-form-group id="create-facture-date_facturation-group" label="Date de facturation" label-for="create-facture-date_facturation-input">
              <b-form-datepicker
                id="create-facture-date_facturation-input"
                name="create-facture-date_facturation-input"
                locale="fr"
                :min="today"
                :show-decade-nav="true"
                placeholder="Choisissez une date"
                v-model="dateFacturation" class="mb-2"
                @input="$v.dateFacturation.$touch()"
                :state="$v.dateFacturation.$dirty ? !$v.dateFacturation.$error : null">
              </b-form-datepicker>
              <small class="text-danger" v-if="!$v.dateFacturation.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
            </b-form-group>
          </div>
          <div v-else>
            <CInput
              label="Date de facturation"
              v-model="dateFacturation" type="date"
              placeholder="AAAA-MM-DD"
              @input="$v.dateFacturation.$touch()"
              :isValid="$v.dateFacturation.$dirty ? !$v.dateFacturation.$error : null"
            >
            <template #description>
              <div v-if="$browserDetect.isIE" class="text-info">
                <small>Vous utilisez Internet Explorer, veuillez entrer votre
                date au format AAAA-MM-DD (Par exemple 1975-11-03)</small>
              </div>
              <small class="text-danger" v-if="!$v.dateFacturation.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
            </template>
            </CInput>
          </div>
        </CCol>
        <CCol sm="6">

          <div v-if="$browserDetect.isSafari">
            <b-form-group id="create-facture-date_echeance-group" label="Date d'échéance" label-for="create-facture-date_echeance-input">
              <b-form-datepicker
                id="create-facture-date_echeance-input"
                name="create-facture-date_echeance-input"
                locale="fr"
                :min="tomorrow"
                :show-decade-nav="true"
                placeholder="Choisissez une date"
                v-model="dateEcheance" class="mb-2"
                @input="$v.dateEcheance.$touch()"
                :state="$v.dateEcheance.$dirty ? !$v.dateEcheance.$error : null">
              </b-form-datepicker>
              <small class="text-danger" v-if="!$v.dateEcheance.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
            </b-form-group>
          </div>
          <div v-else>
            <CInput
              label="Date d'échéance"
              v-model="dateEcheance" type="date"
              placeholder="AAAA-MM-DD"
              @input="$v.dateEcheance.$touch()"
              :isValid="$v.dateEcheance.$dirty ? !$v.dateEcheance.$error : null"
            >
            <template #description>
              <div v-if="$browserDetect.isIE" class="text-info">
                <small>Vous utilisez Internet Explorer, veuillez entrer votre
                date au format AAAA-MM-DD (Par exemple 1975-11-03)</small>
              </div>
              <small class="text-danger" v-if="!$v.dateEcheance.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
            </template>
            </CInput>
          </div>
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="4">
          Est-ce une demande d'accompte ?
        </CCol>
        <CCol sm="8">
          <CInputRadioGroup
            :checked.sync="isAcompte"
            :custom="true"
            :inline="true"
            :options='trueFalseArrayForm'
          />
        </CCol>
      </CRow>

      <CTextarea
        class="mt-2"
        label="Message sur la facture"
        v-model="messageFacture" type="text"
        placeholder="Vous pouvez renseigner un message personnalisé sur la facture"
        maxlength="300"
        @input="$v.messageFacture.$touch()"
        :isValid="$v.messageFacture.$dirty ? !$v.messageFacture.$error : null"
      >
      </CTextarea>


      <CRow class="mt-4">
        <CCol class="text-center">
          <CButton
            type="submit" color="outline-primary"
            shape="pill" block class="px-4" size="lg"
            :disabled="$v.title.$invalid || $v.address.$invalid || $v.delaiPaiement.$invalid || $v.dateFacturation.$invalid || $v.dateEcheance.$invalid || $v.isAcompte.$invalid || $v.messageFacture.$invalid || !isAtLeastOneModalitePaiementChosen">
              Créer votre facture
          </CButton>
        </CCol>
      </CRow>

    </CForm>
  </div>
</template>

<script>
import { required, minLength, maxLength} from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

import { isDate } from '@/validators/validators'

export default {
  name: 'addFactureComponent',
  props: {
    missionTitle: String,
    clientAddress: String
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    const today = this.$dayjs().format('YYYY-MM-DD')
    const currentYear = this.$dayjs().year()
    const tomorrow = this.$dayjs().add(1, 'day').format("YYYY-MM-DD")
    const thirtyDaysAfter = this.$dayjs().add(30, 'day').format("YYYY-MM-DD")
    return {
      exerciceComptable: currentYear,
      title: this.missionTitle,
      address: this.clientAddress,
      delaiPaiement: '30',
      dateFacturation: today,
      today: today,
      dateEcheance: thirtyDaysAfter,
      tomorrow: tomorrow,
      isAcompte: 'false',
      isModalitesVirement: false,
      isModalitesCb: false,
      isModalitesCash: false,
      isModalitesCheque: false,
      messageFacture: '',

      delaiPaiementArrayOptions: [
        { label: '15 jours', value: '15' },
        { label: '30 jours', value: '30' },
        { label: '60 jours', value: '60' },
        { label: 'Payable dès réception', value: 'RECEP' },
      ],
      facturesModalitesPaiementArrayOptions: [
        { label: 'Virement', value: 'VIR' },
        { label: 'Carte bancaire', value: 'CB' },
        { label: 'Espèces', value: 'CASH' },
      ],
      trueFalseArrayForm: [
        { label: 'Oui', value: 'true' },
        { label: "Non", value: 'false' },
      ],
    }
  },
  computed: {
    isAtLeastOneModalitePaiementChosen () {
      if (this.isModalitesVirement || this.isModalitesCb || this.isModalitesCash || this.isModalitesCheque) {
        return true
      }
      return false
    },
    exerciceComptableYears () {
      const currentYear = this.$dayjs().year()

      var years = [{'label': '', 'value': ''}]
      for (var i=currentYear-2; i<=currentYear+2; i++) {
        var value = String(i)
        years.push({'label': value, 'value': i})
      }
      return years
    }
  },
  validations: {
    exerciceComptable: {
      required,
    },
    title: {
      required,
      maxLength: maxLength(149),
      minLength: minLength(2)
    },
    address: {
      required,
      maxLength: maxLength(300),
      minLength: minLength(2)
    },
    delaiPaiement: {
      required,
    },
    dateFacturation: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    dateEcheance: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    isAcompte: {
      required,
    },

    messageFacture: {
      maxLength: maxLength(300),
      minLength: minLength(2)
    },
  },

  created: function() {
    this.$dayjs.locale('fr')
  },

  watch: {
    delaiPaiement: function(newValueDelaiPaiement) {
      const dateFacture = this.$dayjs(this.dateFacturation)
      if (newValueDelaiPaiement == '15') {
        this.dateEcheance = dateFacture.add(15, 'day').format("YYYY-MM-DD")
      }
      else if (newValueDelaiPaiement == '30') {
        this.dateEcheance = dateFacture.add(30, 'day').format("YYYY-MM-DD")
      }
      else if (newValueDelaiPaiement == '60') {
        this.dateEcheance = dateFacture.add(60, 'day').format("YYYY-MM-DD")
      }
      else if (newValueDelaiPaiement == 'RECEP') {
        this.dateEcheance = dateFacture.add(1, 'day').format("YYYY-MM-DD")
      }
    }
  },

  methods: {

    validateFactureCreation () {
      var data = {
        'exerciceComptable': this.exerciceComptable,
        'title': this.title,
        'address': this.address,
        'delaiPaiement': this.delaiPaiement,
        'dateFacturation': this.dateFacturation,
        'dateEcheance': this.dateEcheance,
        'isAcompte': this.isAcompte,
        'isModalitesVirement': this.isModalitesVirement,
        'isModalitesCb': this.isModalitesCb,
        'isModalitesCash': this.isModalitesCash,
        'isModalitesCheque': this.isModalitesCheque,
        'messageFacture': this.messageFacture,

      }
      this.$emit(
        'validateFactureCreation',
         data
      )
    }
  }
}
</script>
